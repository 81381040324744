type TCustomDimension = Record<string, any> | '';

type TCreateCustomDimensionPayload = {
  dimension: number;
  payload?: any;
};

const createCustomDimension = ({ dimension, payload }: TCreateCustomDimensionPayload): TCustomDimension => {
  if (!payload) {
    return '';
  }
  return {
    [`dimension${dimension}`]: payload,
  };
};

export default createCustomDimension;
