import React, { useState, useEffect } from 'react';
import { Trans } from '@ecg-marktplaats/js-react-i18n';
import { TAdmarktItemClient } from 'types/TAdmarkt';
import { TListingClient } from 'types/TListing';
import getSimilarItems from '../../repositories/getSimilarItems';
import Classes from './AdmarktSimilarItems.scss';
import AdmarktSimilarItemsList from './AdmarktSimilarItemsList';

type TAdmarktSimilarItemsProps = {
  sellerId: TListingClient['seller']['id'];
  itemId: TListingClient['itemId'];
  category: TListingClient['category'];
  isHorizontal: boolean;
  isAdmarktSimilarItemsExperimentsEnabled?: boolean;
};

const AdmarktSimilarItems = ({
  sellerId,
  itemId,
  category,
  isHorizontal,
  isAdmarktSimilarItemsExperimentsEnabled,
}: TAdmarktSimilarItemsProps) => {
  const [items, setItems] = useState<TAdmarktItemClient[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(true);

  useEffect(() => {
    (async () => {
      setIsLoading(true);
      setItems(await getSimilarItems({ sellerId, itemId, l2CategoryId: category?.id }));
      setIsLoading(false);
    })();
  }, [sellerId, itemId, category]);

  if (!items.length && !isLoading) {
    return null;
  }

  return (
    <div className={`block-wrapper ${Classes.root} ${isHorizontal ? Classes.rootHorizontal : ''}`}>
      <Trans className={Classes.headerTitle} i18nKey="similarItems.seeOther" />
      <AdmarktSimilarItemsList
        isLoading={isLoading}
        isHorizontal={isHorizontal}
        items={items}
        isAdmarktSimilarItemsExperimentsEnabled={isAdmarktSimilarItemsExperimentsEnabled}
      />
    </div>
  );
};

export default AdmarktSimilarItems;
