import React from 'react';

import AdmarktSimilarItemsListSkeleton from './AdmarktSimilarItemsListSkeleton';
import AdmarktSimilarItem from './AdmarktSimilarItem';

import { TAdmarktItemClient } from 'types/TAdmarkt';

import Classes from './AdmarktSimilarItems.scss';

type TAdmarktSimilarItemsListProps = {
  isLoading: boolean;
  isHorizontal: boolean;
  items: TAdmarktItemClient[];
  isAdmarktSimilarItemsExperimentsEnabled?: boolean;
};

const AdmarktSimilarItemsList = ({
  isLoading,
  items,
  isHorizontal,
  isAdmarktSimilarItemsExperimentsEnabled,
}: TAdmarktSimilarItemsListProps) => (
  <div
    className={`${isAdmarktSimilarItemsExperimentsEnabled ? Classes.similarItemsExperiment : isHorizontal ? Classes.listHorizontal : Classes.list}`}
  >
    {isLoading && !isAdmarktSimilarItemsExperimentsEnabled && (
      <AdmarktSimilarItemsListSkeleton isHorizontal={isHorizontal} />
    )}
    {!isLoading &&
      items.map((item) => (
        <AdmarktSimilarItem
          key={item.itemId}
          item={item}
          isHorizontal={isHorizontal}
          isAdmarktSimilarItemsExperimentsEnabled={isAdmarktSimilarItemsExperimentsEnabled}
        />
      ))}
  </div>
);

export default AdmarktSimilarItemsList;
