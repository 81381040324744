import React, { useState } from 'react';
import { TertiaryIconButton } from '@hz-design-system/web-ui';

import { BANNER_POSITION, bannerPositions } from '../../constants/banners';

import Classes from './BannerTop.scss';

type TBannerTopProps = {
  isStickyCTaExperimentEnabled?: boolean;
};
const BannerTop = ({ isStickyCTaExperimentEnabled }: TBannerTopProps) => {
  const [showBanner, setShowBanner] = useState(true);

  const handleClick = () => {
    setShowBanner(false);
  };

  if (!showBanner) {
    return null;
  }

  return (
    <div className={!isStickyCTaExperimentEnabled ? Classes.root : Classes.rootExperiment}>
      <TertiaryIconButton className={Classes.buttonClose} name="close-small" aria-label="close" onClick={handleClick} />

      <div id={bannerPositions[BANNER_POSITION.STATIC.TOP].id} />
    </div>
  );
};

export default BannerTop;
