import React, { useContext, useEffect, useRef, useState } from 'react';
import { ButtonIcon, MaskedLink } from '@hz-design-system/web-ui';
import { Trans } from '@ecg-marktplaats/js-react-i18n';

import EnvironmentContext from '../../../../contexts/EnvironmentContext';
import { trackGAEvent } from '../../../../utils/gaTracking';
import { appendHashParam, deleteHashParam, getHashParamValue } from '../../../../utils/hashParams';
import registerPhoneClick from '../../repositories/registerPhoneClick';

import LoginDialogContainer from '../../../../components/LoginDialog/LoginDialogContainer';
import Asq from './Asq';
import SellerContactOptionsButton from './SellerContactOptionsButton';

import { TListing, TListingClient, TListingSellerInfo } from 'types/TListing';

import Classes from './SellerContactOptions.scss';
import { CUSTOM_DIMENSIONS, GA_TRACK_EVENTS_ACTIONS } from '../../../../utils/constants';
import createCustomDimension from '../../../../utils/createCustomDimension';
import PaymentProposalButton from './PaymentProposal/PaymentProposalButton';
import BuyItNowButton from './BuyItNow/BuyItNowButton';
import BuyItRefurbished from './BuyItRefurbished/BuyItRefurbished';

let PaymentProposalDialog;
let PhoneDialog;

type TSellerContactOptionsProps = {
  itemId: TListingClient['itemId'];
  title: TListingClient['title'];
  adImageThumbnail: string;
  priceInfo: TListingClient['priceInfo'];
  seller: TListingSellerInfo;
  isCarAd: TListingClient['isCarAd'];
  isCarDealer: TListingClient['flags']['isCarDealer'];
  isAdmarkt: TListingClient['flags']['isAdmarkt'];
  hasCallTracking: TListing['flags']['hasCallTracking'];
  isLeaseCar: TListing['flags']['isLeaseCar'];
  isInBinExcludedCategories?: TListing['flags']['isInBinExcludedCategories'];
};
type GaEventProps = {
  label: string;
};
type HorizontalAdButtonProps = {
  gaEvent?: GaEventProps;
  isPrimary: boolean;
};
type AdButtonConfig = Array<{
  type: 'BuyItNow' | 'Asq' | 'SellersContactWebsite' | 'ShowPhoneNumber' | 'PaymentProposal' | 'BuyItRefurbished';
  Component: ({ gaEvent, isPrimary }: HorizontalAdButtonProps) => React.ReactElement;
  isVisible: boolean;
  isPrimary: boolean;
  gaEvent: GaEventProps;
}>;

type MappingType = {
  HORIZONTAL_AD: AdButtonConfig;
  ADMARKT: AdButtonConfig;
  VERIFIED_REFURBISHED: AdButtonConfig;
};

const HASH_PARAMS = {
  SHOW_PHONE: {
    KEY: 'action',
    VALUE: 'showPhone',
  },
};

type SellersContactWebsiteButtonProps = {
  sellerWebsiteUrl?: string;
  handleWebsiteClick: () => void;
  isPrimary?: boolean;
};

const SellersContactWebsiteButton = ({
  sellerWebsiteUrl,
  handleWebsiteClick,
  isPrimary = false,
}: SellersContactWebsiteButtonProps) => {
  return (
    <MaskedLink
      target="_blank"
      rel="nofollow"
      href={sellerWebsiteUrl as string}
      className={`hz-Button ${isPrimary ? 'hz-Button--primary' : 'hz-Button--secondary'} ${Classes.button} ${Classes.link}`}
      onClick={handleWebsiteClick}
    >
      <ButtonIcon name="website" isStartIcon={false} />
      <Trans className={Classes.text} tagName="span" i18nKey="sellerContact.website" />
    </MaskedLink>
  );
};

type ShowPhoneNumberButtonProps = {
  isPrimary: boolean;
  handlePhoneNumberClick: () => void;
};

const ShowPhoneNumberButton = ({ isPrimary, handlePhoneNumberClick }: ShowPhoneNumberButtonProps) => {
  return (
    <SellerContactOptionsButton isPrimary={isPrimary} className={Classes.button} onClick={handlePhoneNumberClick}>
      <ButtonIcon name="call" isStartIcon={false} />
      <Trans className={Classes.text} tagName="span" i18nKey="sellerContact.showPhoneNumber" />
    </SellerContactOptionsButton>
  );
};

const SellerContactOptions = ({
  itemId,
  title,
  adImageThumbnail,
  priceInfo,
  seller,
  isCarAd,
  isAdmarkt,
  hasCallTracking,
  isCarDealer,
  isInBinExcludedCategories,
  isLeaseCar,
}: TSellerContactOptionsProps) => {
  const {
    name: sellerName,
    phoneNumber,
    isAsqEnabled,
    sellerWebsiteUrl,
    phoneNumberHidden,
    showProfilePicture,
    showSalesRepresentatives,
    financeAvailable,
    id: sellerId,
    sellerType,
    refurbishedAdDeepLink,
  } = seller;

  const {
    xsrfToken,
    userLoggedIn,
    userDetails: { isAdOwner = false } = {},
    hasLicensePlate,
    isBuyItNowEnabled,
    isPaymentProposalEnabled,
    links: { buyItNowPathPrefix },
    isVerifiedRefurbishedAd,
    isStickyCTaExperimentEnabled,
  } = useContext(EnvironmentContext);

  const [showPhoneNumber, setShowPhoneNumber] = useState(false);
  const [isPhoneDialogLoaded, setPhoneDialogLoaded] = useState(false);
  const [showLoginDialog, setShowLoginDialog] = useState(false);
  const [loginSuccessAction, setLoginSuccessAction] = useState('');
  const [showPaymentProposalDialog, setShowPaymentProposalDialog] = useState(false);
  const [isPrimaryButtonVisible, setIsPrimaryButtonVisible] = useState(false);
  const primaryButtonRef = useRef(null);

  const getAdType = () => {
    if (isVerifiedRefurbishedAd) return 'VERIFIED_REFURBISHED';
    if (isAdmarkt) return 'ADMARKT';
    if (isCarAd && !isAdmarkt) return 'CAR_AD';
    return 'HORIZONTAL_AD';
  };

  const AdType = getAdType();

  const reloadAndShowPhoneDialog = () => {
    appendHashParam(HASH_PARAMS.SHOW_PHONE.KEY, HASH_PARAMS.SHOW_PHONE.VALUE);
    document.location.reload();
  };

  const loadPhoneDialog = async (gaEvent?: GaEventProps) => {
    if (!isPhoneDialogLoaded) {
      try {
        PhoneDialog = (await import(/* webpackChunkName: "PhoneDialog" */ './PhoneDialog')).default;
        setPhoneDialogLoaded(true);
        if (gaEvent) {
          trackGAEvent(GA_TRACK_EVENTS_ACTIONS.R2SPhoneBegin, `click-${gaEvent.label}`, {
            ...createCustomDimension({
              dimension: CUSTOM_DIMENSIONS.AD_SELLER_TYPE,
              payload: sellerType,
            }),
            ...createCustomDimension({
              dimension: CUSTOM_DIMENSIONS.AD_HAS_LICENSE_PLATE,
              payload: hasLicensePlate,
            }),
          });
        } else {
          trackGAEvent(GA_TRACK_EVENTS_ACTIONS.R2SPhoneBegin, 'click', {
            ...createCustomDimension({
              dimension: CUSTOM_DIMENSIONS.AD_SELLER_TYPE,
              payload: sellerType,
            }),
            ...createCustomDimension({
              dimension: CUSTOM_DIMENSIONS.AD_HAS_LICENSE_PLATE,
              payload: hasLicensePlate,
            }),
          });
        }
        registerPhoneClick({ itemId, xsrfToken, isCarAd, isLeaseCar });
      } catch (err) {}
    }
  };

  useEffect(() => {
    if (getHashParamValue(HASH_PARAMS.SHOW_PHONE.KEY) === HASH_PARAMS.SHOW_PHONE.VALUE) {
      deleteHashParam(HASH_PARAMS.SHOW_PHONE.KEY);
      if (!phoneNumberHidden) {
        loadPhoneDialog();
        setShowPhoneNumber(true);
      }
    }
    // keep deps empty to run useEffect hook only once on mount
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (AdType !== 'CAR_AD') {
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            setIsPrimaryButtonVisible(entry.isIntersecting);
          });
        },
        {
          rootMargin: '-128px 0px 0px 0px',
          threshold: 0.5,
        },
      );

      if (primaryButtonRef.current) {
        observer.observe(primaryButtonRef.current);
      }

      return () => {
        if (primaryButtonRef.current) {
          observer.disconnect();
        }
      };
    }
  }, []);

  const handleWebsiteClick = (gaEvent?: GaEventProps) => {
    trackGAEvent(
      GA_TRACK_EVENTS_ACTIONS.R2SExternalBegin,
      gaEvent ? gaEvent.label : '',
      createCustomDimension({
        dimension: CUSTOM_DIMENSIONS.AD_SELLER_TYPE,
        payload: sellerType,
      }),
    );
  };

  const buyItNowHandler = (gaEvent?: GaEventProps) => {
    if (gaEvent) {
      trackGAEvent(GA_TRACK_EVENTS_ACTIONS.R2SBINBegin, gaEvent.label);
    } else {
      trackGAEvent(GA_TRACK_EVENTS_ACTIONS.R2SBINBegin);
    }
    location.assign(`${buyItNowPathPrefix}${itemId}`);
  };

  const loadAndShowPaymentDialog = async () => {
    PaymentProposalDialog = (
      await import(/* webpackChunkName: "PaymentProposalDialog" */ './PaymentProposal/PaymentProposalDialog')
    ).default;
    setShowPaymentProposalDialog(true);
  };

  const handleBuyItNowClick = (gaEvent?: GaEventProps) => {
    if (userLoggedIn) {
      buyItNowHandler(gaEvent);
    } else {
      setShowLoginDialog(true);
      setLoginSuccessAction('buyItNow');
    }
  };

  const handlePaymentProposalClick = () => {
    if (userLoggedIn) {
      loadAndShowPaymentDialog();
    } else {
      setShowLoginDialog(true);
      setLoginSuccessAction('paymentProposal');
    }
  };

  const handlePhoneNumberClick = (gaEvent?: GaEventProps) => {
    if (!phoneNumberHidden) {
      loadPhoneDialog(gaEvent);
      setShowPhoneNumber(true);
    } else {
      setShowLoginDialog(true);
      setLoginSuccessAction('phone');
    }
  };

  const handleLoginSuccess = () => {
    setShowLoginDialog(false);
    switch (loginSuccessAction) {
      case 'phone':
        reloadAndShowPhoneDialog();
        break;

      case 'buyItNow':
        buyItNowHandler();
        break;

      case 'paymentProposal':
        loadAndShowPaymentDialog();
        break;
    }
  };

  const handlePhoneDialogClose = () => {
    setShowPhoneNumber(false);
  };

  // do not render an empty block if nothing to show
  if ((!isAsqEnabled || (isAsqEnabled && isAdOwner)) && !sellerWebsiteUrl && !phoneNumber && !showLoginDialog) {
    return null;
  }

  const mapping: MappingType = {
    HORIZONTAL_AD: [
      {
        type: 'BuyItNow',
        Component: ({ gaEvent }: HorizontalAdButtonProps) => (
          <BuyItNowButton
            onClick={() => {
              handleBuyItNowClick(gaEvent);
            }}
          />
        ),
        isVisible: isBuyItNowEnabled && isAsqEnabled && !isAdOwner,
        isPrimary: true,
        gaEvent: {
          label: 'sticky',
        },
      },
      {
        type: 'Asq',
        Component: ({ isPrimary, gaEvent }: HorizontalAdButtonProps) => (
          <Asq
            isPrimary={isPrimary}
            itemId={itemId}
            isCarDealer={isCarDealer}
            isCarAd={isCarAd}
            title={title}
            sellerName={sellerName}
            sellerLocation={seller.location}
            sellerId={sellerId}
            adImageThumbnail={adImageThumbnail}
            showProfilePicture={showProfilePicture}
            showSalesRepresentatives={showSalesRepresentatives}
            financeAvailable={financeAvailable}
            sellerType={sellerType}
            priceInfo={priceInfo}
            gaEvent={gaEvent}
          />
        ),
        isVisible: isAsqEnabled && !isAdOwner,
        isPrimary: !isBuyItNowEnabled,
        gaEvent: {
          label: 'sticky',
        },
      },
      {
        type: 'SellersContactWebsite',
        Component: () => (
          <SellersContactWebsiteButton
            sellerWebsiteUrl={sellerWebsiteUrl}
            handleWebsiteClick={() => handleWebsiteClick()}
          />
        ),
        isVisible: Boolean(sellerWebsiteUrl),
        isPrimary: false,
        gaEvent: {
          label: 'sticky',
        },
      },
      {
        type: 'ShowPhoneNumber',
        Component: ({ isPrimary, gaEvent }: HorizontalAdButtonProps) => (
          <ShowPhoneNumberButton isPrimary={isPrimary} handlePhoneNumberClick={() => handlePhoneNumberClick(gaEvent)} />
        ),
        isVisible: Boolean(phoneNumberHidden || phoneNumber),
        isPrimary: !isBuyItNowEnabled && !sellerWebsiteUrl && !isAsqEnabled,
        gaEvent: {
          label: 'sticky',
        },
      },
      {
        type: 'PaymentProposal',
        Component: ({ isPrimary }) => (
          <PaymentProposalButton isPrimary={isPrimary} onClick={handlePaymentProposalClick} />
        ),
        isVisible: isPaymentProposalEnabled && isAsqEnabled && !isAdOwner,
        isPrimary: !isBuyItNowEnabled && !phoneNumberHidden && !phoneNumber && !sellerWebsiteUrl,
        gaEvent: {
          label: 'sticky',
        },
      },
    ],
    ADMARKT: [
      {
        type: 'SellersContactWebsite',
        Component: ({ gaEvent }) => (
          <SellersContactWebsiteButton
            sellerWebsiteUrl={sellerWebsiteUrl}
            handleWebsiteClick={() => handleWebsiteClick(gaEvent)}
            isPrimary={true}
          />
        ),
        isVisible: Boolean(sellerWebsiteUrl),
        isPrimary: true,
        gaEvent: {
          label: 'sticky',
        },
      },
      {
        type: 'ShowPhoneNumber',
        Component: ({ isPrimary, gaEvent }: HorizontalAdButtonProps) => (
          <ShowPhoneNumberButton isPrimary={isPrimary} handlePhoneNumberClick={() => handlePhoneNumberClick(gaEvent)} />
        ),
        isVisible: Boolean(phoneNumberHidden || phoneNumber),
        isPrimary: !sellerWebsiteUrl,
        gaEvent: {
          label: 'sticky',
        },
      },
      {
        type: 'Asq',
        Component: ({ isPrimary, gaEvent }: HorizontalAdButtonProps) => (
          <Asq
            isPrimary={isPrimary}
            itemId={itemId}
            isCarDealer={isCarDealer}
            isCarAd={isCarAd}
            title={title}
            sellerName={sellerName}
            sellerLocation={seller.location}
            sellerId={sellerId}
            adImageThumbnail={adImageThumbnail}
            showProfilePicture={showProfilePicture}
            showSalesRepresentatives={showSalesRepresentatives}
            financeAvailable={financeAvailable}
            sellerType={sellerType}
            priceInfo={priceInfo}
            gaEvent={gaEvent}
          />
        ),
        isVisible: isAsqEnabled && !isAdOwner,
        isPrimary: !(phoneNumberHidden || phoneNumber) && !sellerWebsiteUrl,
        gaEvent: {
          label: 'sticky',
        },
      },
      {
        type: 'PaymentProposal',
        Component: ({ isPrimary }) => (
          <PaymentProposalButton isPrimary={isPrimary} onClick={handlePaymentProposalClick} />
        ),
        isVisible: isAsqEnabled && !isAdOwner && isPaymentProposalEnabled && !isInBinExcludedCategories,
        isPrimary: !phoneNumberHidden && !phoneNumber && !sellerWebsiteUrl,
        gaEvent: {
          label: 'sticky',
        },
      },
    ],
    VERIFIED_REFURBISHED: [
      {
        type: 'BuyItRefurbished',
        Component: ({ gaEvent }) => (
          <BuyItRefurbished
            sellerName={sellerName}
            refurbishedAdDeepLink={refurbishedAdDeepLink as string}
            sellerId={sellerId}
            priceCents={priceInfo?.priceCents}
            gaEvent={gaEvent}
          />
        ),
        isVisible: Boolean(refurbishedAdDeepLink),
        isPrimary: true,
        gaEvent: {
          label: 'sticky',
        },
      },
    ],
  };

  const selectedMapping = mapping[AdType as keyof MappingType];
  const primaryButtonSticky = selectedMapping?.find((button) => {
    return button.isPrimary && button.isVisible;
  });

  const PrimaryButtonStickyComponent = primaryButtonSticky?.Component as React.ComponentType<HorizontalAdButtonProps>;

  switch (AdType) {
    case 'VERIFIED_REFURBISHED':
      return (
        <div className={`block-wrapper-s block-wrapper-s--hide-top ${Classes.root}`}>
          {mapping.VERIFIED_REFURBISHED.map(({ Component, isPrimary, isVisible }) => {
            return isVisible ? (
              <div ref={isPrimary ? primaryButtonRef : null} key={Component.toString()}>
                <Component isPrimary={isPrimary} />
              </div>
            ) : null;
          })}

          {/* Sticky primary button */}
          {isStickyCTaExperimentEnabled && !isPrimaryButtonVisible && primaryButtonRef.current && (
            <div className={Classes.stickyContainerFixed}>
              <PrimaryButtonStickyComponent
                isPrimary={primaryButtonSticky?.isPrimary ?? false}
                gaEvent={primaryButtonSticky?.gaEvent}
              />
            </div>
          )}
        </div>
      );

    case 'ADMARKT':
      return (
        <div className={`block-wrapper-s block-wrapper-s--hide-top ${Classes.root}`}>
          {mapping.ADMARKT.map(({ Component, isPrimary, isVisible }) => {
            return isVisible ? (
              <div ref={isPrimary ? primaryButtonRef : null} key={Component.toString()}>
                <Component isPrimary={isPrimary} />
              </div>
            ) : null;
          })}

          {/* Dialogs */}
          {isPhoneDialogLoaded && showPhoneNumber && (
            <PhoneDialog
              seller={seller}
              itemId={itemId}
              hasCallTracking={hasCallTracking}
              onClose={handlePhoneDialogClose}
            />
          )}
          {showLoginDialog && <LoginDialogContainer onClose={setShowLoginDialog} onSuccess={handleLoginSuccess} />}
          {showPaymentProposalDialog && (
            <PaymentProposalDialog itemId={itemId} hideModal={() => setShowPaymentProposalDialog(false)} />
          )}

          {/* Sticky primary button */}
          {isStickyCTaExperimentEnabled && !isPrimaryButtonVisible && primaryButtonRef.current && (
            <div className={Classes.stickyContainerFixed}>
              <PrimaryButtonStickyComponent
                isPrimary={primaryButtonSticky?.isPrimary ?? false}
                gaEvent={primaryButtonSticky?.gaEvent}
              />
            </div>
          )}
        </div>
      );

    case 'CAR_AD':
      return (
        <div className={`block-wrapper-s block-wrapper-s--hide-top ${Classes.root}`}>
          {(phoneNumberHidden || phoneNumber) && (
            <SellerContactOptionsButton isPrimary className={Classes.button} onClick={handlePhoneNumberClick}>
              <ButtonIcon name="call" isStartIcon={false} />
              <Trans className={Classes.text} tagName="span" i18nKey="sellerContact.showPhoneNumber" />
            </SellerContactOptionsButton>
          )}
          {isAsqEnabled && !isAdOwner && (
            <Asq
              itemId={itemId}
              isCarDealer={isCarDealer}
              isCarAd={isCarAd}
              title={title}
              sellerName={sellerName}
              sellerLocation={seller.location}
              isPrimary={!phoneNumberHidden && !phoneNumber}
              sellerId={sellerId}
              adImageThumbnail={adImageThumbnail}
              showProfilePicture={showProfilePicture}
              showSalesRepresentatives={showSalesRepresentatives}
              financeAvailable={financeAvailable}
              sellerType={sellerType}
              priceInfo={priceInfo}
              isLeaseCar={isLeaseCar}
            />
          )}
          {sellerWebsiteUrl && (
            <MaskedLink
              target="_blank"
              rel="nofollow"
              href={sellerWebsiteUrl}
              className={`hz-Button hz-Button--secondary ${Classes.button} ${Classes.link}`}
              onClick={() => handleWebsiteClick()}
            >
              <ButtonIcon name="website" isStartIcon={false} />
              <Trans className={Classes.text} tagName="span" i18nKey="sellerContact.website" />
            </MaskedLink>
          )}
          {isPaymentProposalEnabled && isAsqEnabled && !isAdOwner && (
            <PaymentProposalButton
              isPrimary={!phoneNumberHidden && !phoneNumber && !sellerWebsiteUrl}
              onClick={handlePaymentProposalClick}
            />
          )}

          {/* Dialogs */}
          {isPhoneDialogLoaded && showPhoneNumber && (
            <PhoneDialog
              seller={seller}
              itemId={itemId}
              hasCallTracking={hasCallTracking}
              onClose={handlePhoneDialogClose}
            />
          )}
          {showLoginDialog && <LoginDialogContainer onClose={setShowLoginDialog} onSuccess={handleLoginSuccess} />}
          {showPaymentProposalDialog && (
            <PaymentProposalDialog itemId={itemId} hideModal={() => setShowPaymentProposalDialog(false)} />
          )}
        </div>
      );

    case 'HORIZONTAL_AD':
    default:
      return (
        <div className={`block-wrapper-s block-wrapper-s--hide-top ${Classes.root}`}>
          {mapping.HORIZONTAL_AD.map(({ Component, isPrimary, isVisible }) => {
            return isVisible ? (
              <div ref={isPrimary ? primaryButtonRef : null} key={Component.toString()}>
                <Component isPrimary={isPrimary} />
              </div>
            ) : null;
          })}

          {/* Dialogs */}
          {isPhoneDialogLoaded && showPhoneNumber && (
            <PhoneDialog
              seller={seller}
              itemId={itemId}
              hasCallTracking={hasCallTracking}
              onClose={handlePhoneDialogClose}
            />
          )}
          {showLoginDialog && <LoginDialogContainer onClose={setShowLoginDialog} onSuccess={handleLoginSuccess} />}
          {showPaymentProposalDialog && (
            <PaymentProposalDialog itemId={itemId} hideModal={() => setShowPaymentProposalDialog(false)} />
          )}

          {/* Sticky primary button */}
          {isStickyCTaExperimentEnabled && !isPrimaryButtonVisible && primaryButtonRef.current && (
            <div className={Classes.stickyContainerFixed}>
              <PrimaryButtonStickyComponent
                isPrimary={primaryButtonSticky?.isPrimary ?? false}
                gaEvent={primaryButtonSticky?.gaEvent}
              />
            </div>
          )}
        </div>
      );
  }
};

export default SellerContactOptions;
