import React, { useContext } from 'react';
import { SecondaryButton, Asset } from '@hz-design-system/web-ui';
import { Trans, I18nContext } from '@ecg-marktplaats/js-react-i18n';
import { trackGAEvent } from '../../../utils/gaTracking';
import { GA_TRACK_EVENTS_ACTIONS } from '../../../utils/constants';

import Classes from './InspectionService.scss';

const InspectionService = () => {
  const { t } = useContext(I18nContext);

  const handleClick = () => {
    trackGAEvent(GA_TRACK_EVENTS_ACTIONS.OKNBannerClick);
    const inspectionServiceLink = t('inspectionService.url');
    window.open(inspectionServiceLink, '_blank');
  };

  return (
    <div className={`block-wrapper-s ${Classes.root}`}>
      <Asset.Fluid name="trust-and-safety-negociate-cars" className={Classes.image} />
      <div className={Classes.content}>
        <Trans i18nKey="inspectionService.title" tagName="p" className={Classes.title} />
        <Trans i18nKey="inspectionService.descriptionA" tagName="p" className={Classes.description} />
      </div>
      <SecondaryButton data-testid="OKNBannerButton" className={Classes.button} onClick={handleClick}>
        <Trans tagName="span" i18nKey="inspectionService.buttonLabel" />
      </SecondaryButton>
    </div>
  );
};

export default InspectionService;
