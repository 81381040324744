import React, { useEffect, useState, useRef } from 'react';
import ReactDOM from 'react-dom';

import AdmarktSimilarItems from '../components/AdmarktSimilarItems/AdmarktSimilarItems';
import { TListingClient } from 'types/TListing';

type TAdmarktSimilarItemsContainerExperimentProps = {
  sellerId: TListingClient['seller']['id'];
  itemId: TListingClient['itemId'];
  category: TListingClient['category'];
  isAdmarktSimilarItemsExperimentsEnabled?: boolean;
};

const AdmarktSimilarItemsContainer = ({
  itemId,
  sellerId,
  category,
  isAdmarktSimilarItemsExperimentsEnabled,
}: TAdmarktSimilarItemsContainerExperimentProps) => {
  const [isShownAtTop, setIsShownAtTop] = useState(false);
  const elementRef = useRef<HTMLDivElement | null>(null);

  useEffect(() => {
    let rootElement;

    const handleShowAtTop = () => {
      if (!elementRef.current) {
        elementRef.current = document.createElement('div');
        elementRef.current.className = 'display-block-m';
        rootElement = document.getElementById('similar-items-top-root');
        rootElement.appendChild(elementRef.current);
        setIsShownAtTop(true);
      }
      rootElement.scrollIntoView({ behavior: 'smooth', block: 'end', inline: 'nearest' });
    };

    window.addEventListener('asqSent', handleShowAtTop, false);

    return () => {
      window.removeEventListener('asqSent', handleShowAtTop, false);
      rootElement.removeChild(elementRef.current);
    };
  }, []);

  const admarktSimilarItemsComponent = () => {
    return (
      <AdmarktSimilarItems
        itemId={itemId}
        sellerId={sellerId}
        category={category}
        isHorizontal={isShownAtTop}
        isAdmarktSimilarItemsExperimentsEnabled={isAdmarktSimilarItemsExperimentsEnabled}
      />
    );
  };

  return isShownAtTop && elementRef.current
    ? ReactDOM.createPortal(admarktSimilarItemsComponent(), elementRef.current)
    : admarktSimilarItemsComponent();
};

export default AdmarktSimilarItemsContainer;
