import React from 'react';

import { BANNER_POSITION, bannerPositions } from '../../constants/banners';

import Classes from './BannerBottom.scss';
type TBannerBottomProps = {
  isAdmarktSimilarItemsEnabled?: boolean;
};

const BannerBottom = ({ isAdmarktSimilarItemsEnabled }: TBannerBottomProps) => (
  <div
    id={bannerPositions[BANNER_POSITION.STATIC.BOTTOM].id}
    className={isAdmarktSimilarItemsEnabled ? Classes.experimentRoot : Classes.root}
  />
);

export default BannerBottom;
