import React, { useCallback, useContext, useEffect } from 'react';
import Cookies from 'js-cookie';

import { /*queuePosition,*/ displayQueuedPosition } from './utils/banners';
import adobeRegistration from './utils/adobeRegistration';
import getRsiSegments from '../../repositories/getRsiSegments';
import EnvironmentContext from '../../../../contexts/EnvironmentContext';
import { BANNER_POSITION, bannerPositions, displayCookies } from '../../constants/banners';

import BannerTop from './BannerTop';
import BannerRight from './BannerRight';
import BannerOutside from './BannerOutside';
import BannerMid from './BannerMid';
import BannerBottom from './BannerBottom';
import BannerAction from './BannerAction';
import BannerGalleryModal from './BannerGalleryModal';
import BannerMapModal from './BannerMapModal';
import BannerButton from './BannerButton';
import BannerDealerTop from './BannerDealerTop';

type TBannersProps = {
  position: string;
};

let rsiSegmentsIsCalled = false;

const Banners = ({ position }: TBannersProps) => {
  const {
    links: { audienceTargetingUrl },
    xsrfToken,
    tenantContext: { tenant, locale },
    encryptedUserId,
    fingerPrint,
    audienceTargeting: {
      isEnabled,
      crmId,
      partnerId,
      customerOrganisationId,
      l1CategoryId,
      l1CategoryName,
      l2CategoryId,
      l2CategoryName,
    },
    withBanners,
    withBannersFromSeller,
    isAdobeDmpPurposeAccepted,
    isStickyCTaExperimentEnabled,
    isAdmarktSimilarItemsEnabled,
  } = useContext(EnvironmentContext);

  const callAudienceTargetApi = useCallback(async () => {
    try {
      rsiSegmentsIsCalled = true;

      const rsiSegments = await getRsiSegments({
        url: audienceTargetingUrl,
        xsrfToken,
        encryptedUserId,
        fingerPrint,
        l1CategoryId,
        l1CategoryName,
        l2CategoryId,
        l2CategoryName,
        locale,
        uuid: Cookies.get(displayCookies.ADOBE_IDENTIFIER),
        tenant: tenant.toLocaleUpperCase(),
      });

      window.ecgJsBanners?.addAdobeRsiSegments(rsiSegments?.segments);
    } catch {
      rsiSegmentsIsCalled = false;
    }
  }, [
    audienceTargetingUrl,
    encryptedUserId,
    fingerPrint,
    l1CategoryId,
    l1CategoryName,
    l2CategoryId,
    l2CategoryName,
    locale,
    tenant,
    xsrfToken,
  ]);

  useEffect(() => {
    if (position && (withBanners || withBannersFromSeller)) {
      // if the banner library loads after banner positions,
      // library would be triggering event as 'banner-lib-loaded'
      // by listening this event, queued positions will be called
      // window.addEventListener('banner-lib-loaded', displayQueuedPosition);

      if (isAdobeDmpPurposeAccepted && isEnabled && !rsiSegmentsIsCalled) {
        // await is not applied for this function to keep the logic same as current homepage
        // it can be a experiment idea, loading banners after having the segmentation data
        adobeRegistration({
          crmId,
          partnerId,
          customerOrganisationId,
          encryptedUserId,
        });

        if (Cookies.get(displayCookies.ADOBE_IDENTIFIER)) {
          callAudienceTargetApi();
        }
      }

      // queuePosition(position);
    }

    return () => {
      window.removeEventListener('banner-lib-loaded', displayQueuedPosition);
    };
  }, [
    callAudienceTargetApi,
    crmId,
    customerOrganisationId,
    encryptedUserId,
    isEnabled,
    partnerId,
    position,
    withBanners,
    isAdobeDmpPurposeAccepted,
    withBannersFromSeller,
  ]);

  if (withBannersFromSeller) {
    return <BannerDealerTop />;
  }

  if (!withBanners) {
    return null;
  }

  switch (position) {
    case BANNER_POSITION.STATIC.TOP:
      return <BannerTop isStickyCTaExperimentEnabled={isStickyCTaExperimentEnabled} />;
    case BANNER_POSITION.STATIC.RIGHT1:
      return <BannerOutside bannerPositionId={bannerPositions[BANNER_POSITION.STATIC.RIGHT1].id} />;
    case BANNER_POSITION.STATIC.LEFT:
      return <BannerOutside bannerPositionId={bannerPositions[BANNER_POSITION.STATIC.LEFT].id} />;
    case BANNER_POSITION.STATIC.RIGHT:
      return <BannerRight />;
    case BANNER_POSITION.STATIC.MID:
      return <BannerMid />;
    case BANNER_POSITION.STATIC.BOTTOM:
      return <BannerBottom isAdmarktSimilarItemsEnabled={isAdmarktSimilarItemsEnabled} />;
    case BANNER_POSITION.STATIC.ACTION:
      return <BannerAction />;
    case BANNER_POSITION.DYNAMIC.IMAGE:
      return <BannerGalleryModal />;
    case BANNER_POSITION.DYNAMIC.MAP:
      return <BannerMapModal />;
    case BANNER_POSITION.STATIC.BUTTON1:
      return <BannerButton bannerPositionId={bannerPositions[BANNER_POSITION.STATIC.BUTTON1].id} />;
    case BANNER_POSITION.STATIC.BUTTON2:
      return <BannerButton bannerPositionId={bannerPositions[BANNER_POSITION.STATIC.BUTTON2].id} />;
    default:
      return null;
  }
};

export default Banners;
